<template>
  <div></div>
</template>

<script>
import { wxAuth } from "../../api/user";
export default {
  created() {
    const code = location.search.split("code=")[1].split("&")[0];
    wxAuth({
      code: code,
    }).then((res) => {
      sessionStorage.setItem("wxUser", JSON.stringify(res.data));
      location.replace(sessionStorage.getItem("nowUrl"));
    });
  },
};
</script>
